import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import Image from "gatsby-image";
import { Trans } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";

export const pageQuery = graphql`
  query($language: String!) {
    cover_1: file(
      relativePath: { eq: "editorial/images-editorial-cover-1.jpg" }
    ) {
      ...fluidImage
    }
    cover_2: file(
      relativePath: { eq: "editorial/images-editorial-cover-2.jpg" }
    ) {
      ...fluidImage
    }
    cover_3: file(
      relativePath: { eq: "editorial/images-editorial-cover-3.jpg" }
    ) {
      ...fluidImage
    }
    cover_4: file(
      relativePath: { eq: "editorial/images-editorial-cover-4.jpg" }
    ) {
      ...fluidImage
    }
    spread_1: file(
      relativePath: { eq: "editorial/images-editorial-spread-1.jpg" }
    ) {
      ...fluidImage
    }
    spread_2: file(
      relativePath: { eq: "editorial/images-editorial-spread-2.jpg" }
    ) {
      ...fluidImage
    }
    spread_3: file(
      relativePath: { eq: "editorial/images-editorial-spread-3.jpg" }
    ) {
      ...fluidImage
    }
    spread_4: file(
      relativePath: { eq: "editorial/images-editorial-spread-4.jpg" }
    ) {
      ...fluidImage
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Editorial = ({ data }) => {
  const shortDescription =
    "En Estudio 94 tenemos mucha experiencia en diseño editorial: revistas, catálogos, folletos...";
  const description = [
    "En Estudio 94 tenemos mucha experiencia en diseño editorial: revistas, catálogos, folletos..., para los que siempre procuramos equilibrar su función práctica con su estética e identidad de marca.",
    "Hemos trabajado en muchos proyectos corporativos, preparando revistas y material promocional dirigido a clientes, donde prima la estética con el objetivo de llamar la atención y parecer más atractivo al potencial comprador.",
    "Por otra parte, también hemos trabajado en numerosos catálogos industriales, donde hay que contener gran densidad de información práctica sobre los productos ofrecidos, por lo que hay que priorizar la utilidad y funcionalidad del libro.",
  ];

  const covers = [
    data.cover_1.childImageSharp.fluid,
    data.cover_2.childImageSharp.fluid,
    data.cover_3.childImageSharp.fluid,
    data.cover_4.childImageSharp.fluid,
  ];

  const spreads = [
    data.spread_1.childImageSharp.fluid,
    data.spread_2.childImageSharp.fluid,
    data.spread_3.childImageSharp.fluid,
    data.spread_4.childImageSharp.fluid,
  ];

  const [iter, setIter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setIter((i) => (i + 1) % 4), 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Layout pagename="editorial_services">
      <SEO title="Diseño editorial" description={shortDescription} />
      <div className="page_content">
        <div className="service__text">
          <h3 className="service__title">
            <Trans>Diseño editorial</Trans>
          </h3>
          {description.map((paragraph, i) => (
            <p key={`description-${i}`} className="service__description">
              <Trans>{paragraph}</Trans>
            </p>
          ))}
        </div>
        <div className="service__media">
          <Image className="cover-1" fluid={covers[iter % 4]} />
          <Image className="cover-2" fluid={covers[(iter + 1) % 4]} />
          <Image className="spread-1" fluid={spreads[iter % 4]} />
          <Image className="spread-2" fluid={spreads[(iter + 1) % 4]} />
          <Image className="spread-3" fluid={spreads[(iter + 2) % 4]} />
        </div>
      </div>
    </Layout>
  );
};

export default Editorial;
